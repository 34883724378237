
import React, { FunctionComponent, useState, useEffect } from "react";

import './index.css';
import { fetchSetPlanAction } from "../../../services/setCtrlProp";
import { MbrType } from "../../../models/mbr";
import { convertToPlanActionsTypeFromJson, GrillePropType, PlanActionsType } from "../../../models/ctrlProp";
import { EntryType } from "../../../models/entry";

interface PlanActionsComponentProps {
  mbr: MbrType,
  plan?: PlanActionsType;
  rsd: EntryType,
  ctrl: GrillePropType;
  onPlanChanging: ( plan: PlanActionsType ) => void;
};

const PlanActionsComponent: FunctionComponent<PlanActionsComponentProps> = ( { mbr, plan, rsd, ctrl, onPlanChanging } ) => {
  const [dateEcheance, setDateEcheance] = useState('');
  const [message, setMessage] = useState('');

  const [isLeve, setIsLeve] = useState(true);
  const [canModify, setCanModify] = useState(false);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if( plan ) {
      setDateEcheance(plan.echeance);
      setMessage(plan.txt);
      setIsLeve(( plan.fin !== '' ));
      setCanModify(( plan.id === 0 ))
    }
  }, [plan]);
  const handelEcheancePlan = ( v: string ) => {
    setDateEcheance(v);
    setIsModified(true);
  };
  const handelMessagePlan = ( v: string ) => {
    setMessage(v);
    setIsModified(true);
  };
  const handelLevePlan = () => {
    if( plan && ( plan.date === ctrl.time ) ) {
      setIsLeve(true);
      setCanModify(true);
    } else {
      setLeve('1');
    }
  };
  const handelNewPlan = () => {
    if( plan && ( plan.date === ctrl.time ) ) {
      setIsLeve(true);
      setCanModify(true);
    } else {
      setLeve('2');
    }
  };
  const handelSavePlan = () => {
    if( ctrl.id && ( ctrl.id > 0 ) ) {
      savePlan();
    }
  };
  const savePlan = async () => {
    try{
      const newPlan: PlanActionsType = {
        id: ( plan ) ? plan.id : 0,
        date: ( plan ) ? plan.date : 0,
        echeance: dateEcheance,
        fin: '',
        txt: message,
      }
      const response = await fetchSetPlanAction(mbr.id, mbr.mac, rsd.id, ( ( ctrl.id ) ? ctrl.id : 0 ), newPlan);

      if( ( response.result !== undefined ) && response.result ) {
        const planReceived = response.data;

        onPlanChanging(convertToPlanActionsTypeFromJson(planReceived));
      } else if( response.data !== undefined ) {
        console.error(adrFile+'savePlan::ERROR', response.data.txt);
      } else {
        console.error(adrFile+'savePlan::ERROR == réponse non valide');
      }
    } catch( error ) {
      console.error('savePlan::ERROR', error);
    }
  }
  const setLeve = async ( fin: string ) => {
    if( plan && ( plan.id > 0 ) ) {
      try{
        const newPlan: PlanActionsType = {
          id: plan.id,
          date: plan.date,
          echeance: plan.echeance,
          fin: fin,
          txt: plan.txt,
        };
        const response = await fetchSetPlanAction(mbr.id, mbr.mac, rsd.id, ( ( ctrl.id ) ? ctrl.id : 0 ), newPlan);

        if( ( response.result !== undefined ) && response.result ) {
          const planReceived = response.data;

          setIsLeve(true);
          setCanModify(true);
          onPlanChanging(convertToPlanActionsTypeFromJson(planReceived));
        } else if( response.data !== undefined ) {
          console.error(adrFile+'savePlan::ERROR', response.data.txt);
        } else {
          console.error(adrFile+'savePlan::ERROR == réponse non valide');
        }
      } catch( error ) {
        console.error('setLeve::ERROR', error);
      }
    }
  };

  return (
    <div className="plan-actions-component">
      <div className="plan-actions-content">
        <div className="echeance-container">
          <label>Échéance</label>
          <input 
            type="date" 
            value={ dateEcheance } 
            onChange={ ( e ) => handelEcheancePlan(e.target.value) } 
            disabled={ ( !( ( plan ) && ( plan.id > 0 ) ) || !canModify ) }
          />
        </div>

        <div className="message-container">
          <textarea 
            value={ message } 
            onChange={ ( e ) => handelMessagePlan(e.target.value) }
            disabled={ ( !( ( plan ) && ( plan.id > 0 ) ) || !canModify ) }
          />
        </div>

        <div className="buttons-container">
          <button
            className="btn btn-std"
            style={ { opacity: ( plan && ( plan.id > 0 ) && !isLeve ) ? "1" : "0.5" } }
            key="leve"
            onClick={ handelLevePlan }
          >Lever</button>

          <button
            className="btn btn-std"
            style={ { opacity: ( ( plan ) && ( plan.id > 0 ) && !isLeve && !isModified && !canModify ) ? "1" : "0.5" } }
            key="modifier"
            onClick={ handelNewPlan }
          >Modifier</button>

          <button
           className="btn btn-std"
           style={ { opacity: ( isModified ) ? "1" : "0.5" } }
           key="save"
           onClick={ handelSavePlan }
          >Enregistrer</button>
        </div>
      </div>
    </div>
  )
}

export default PlanActionsComponent;
