
export type ReceveidAgenceType = {
  id: number;
  txt: string;
};
export type AgenceType = {
  id: number,
  txt: string,
  contactTxt?: string,
  contactMail?: string,
  responsableTxt?: string,
  responsableMail?: string,
};
export const initialAgence : AgenceType = {
  id: 0,
  txt: '',
  contactTxt: '',
  contactMail: '',
  responsableTxt: '',
  responsableMail: '',
};
export const convertToAgenceType = ( data: ReceveidAgenceType ): AgenceType => {
  return {
    id: data.id,
    txt: data.txt,
  }
};
export const mapOfAgenceType = ( data: ReceveidAgenceType[] ): AgenceType[] => {
  return Object.values(data).map((agc) => {
    return {
      id: agc.id,
      txt: agc.txt,
    }
  });
}
