
import React, { FunctionComponent, useState, FormEvent } from "react";

import './index.css';

interface SendRapportComponentProps {
  debugg?: boolean;
};

const SendRapportComponent: FunctionComponent<SendRapportComponentProps> = ( { debugg } ) => {
  const [isDesordreTechnique, setIfDesordreTechnique] = useState(false);
  const [message, setMessage] = useState('');

  const handelSubmit = (event: FormEvent) => {
    event.preventDefault();

    const formData = {
      desordre: isDesordreTechnique,
      message: message,
    };
  };

  return (
    <div className="send-rapport-component">
      <div className="send-rapport-content">
        <form onSubmit={ handelSubmit }>
          <div className="desodre-container">
            <label>
              <input 
                type="checkbox" 
                name="desordre" 
                className="filled-in"
                checked={ isDesordreTechnique }
                onChange={ ( e ) => setIfDesordreTechnique(e.target.checked) }
              />
              <span>Désordre technique</span>
            </label>
          </div>

          <div className="message-container">
            <label>Laisser un message</label>
            <textarea 
              name="message"
              value={ message }
              onChange={ ( e ) => setMessage(e.target.value) }
            ></textarea>
          </div>

          <button
            className="btn btn-std"
            key="send"
            onClick={ handelSubmit }
          >ENVOYER</button>
        </form>
        
      </div>
    </div>
  )
}

export default SendRapportComponent;
