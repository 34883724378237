
import React, { FunctionComponent, useRef, useEffect, useState } from 'react';
import SignaturePad from 'signature_pad';

import './signature.css';
import { fetchSetSignature } from '../../services/setCtrlProp';
import { MbrType } from '../../models/mbr';

interface SignatureProps {
  mbr: MbrType;
  idCtrl: number;
  numberOfSignatures: number;
  isSigned: boolean;
  onSignature: ( sigs: string[] ) => void;
};

const SignatureComponent: FunctionComponent<SignatureProps> = ({ mbr, idCtrl, numberOfSignatures, isSigned, onSignature }) => {
  const adrFile = '/components/signature/signature.tsx::SignatureComponent::';

  const [canModify, setCanModify] = useState(true);

  const canvasRefs = useRef<(HTMLCanvasElement | null)[]>([]);
  const signaturePads = useRef<SignaturePad[]>([]);

  useEffect(() => {
    canvasRefs.current.forEach((canvas, index) => {
      if( canvas ) {
        signaturePads.current[index] = new SignaturePad(canvas);
      }
    });
  }, []);
  useEffect(() => {
    setCanModify(!isSigned);
  }, [isSigned]);
  const handelClearSignature = (index: number) => {
    if( canModify ) {
      signaturePads.current[index].clear();
    }
  };
  const saveAllSignatures = async () => {
    if( canModify && ( idCtrl > 0 ) ) {
      const savedSignatures = signaturePads.current.map(( signaturePad, index ) => {
        if( !signaturePad.isEmpty() ) {
          const dataURL = signaturePad.toDataURL();

          return dataURL;
        } else {
          return '';
        }
      });
      
      signaturePads.current.forEach(( signaturePad ) => {
        signaturePad.off();
      });

      try {
        const response = await fetchSetSignature(mbr.id, mbr.mac, idCtrl, savedSignatures, true);

        if( ( response.result !== undefined ) && response.result ) {
          onSignature(response.data);
        } else if( response.data !== undefined ) {
          console.error(adrFile+'saveAllSignatures::ERROR', response.data.txt);
        } else {
          console.error(adrFile+'saveAllSignatures::ERROR == réponse non valide');
        }
      } catch( error ) {
        console.error(adrFile+'saveAllSignatures::ERROR', error);
      }

      onSignature(savedSignatures);
      setCanModify(false);
    }
  };

  return (
    <div>
      {[...Array(numberOfSignatures)].map((_, index) => (
        <div key={index}>
          <canvas ref={ (el) => (canvasRefs.current[index] = el) }/>
          <div>
            <button
              className='btn btn-std'
              onClick={ () => handelClearSignature(index) }>
                Vider
            </button>
          </div>
        </div>
      ))}

      <button 
        className='btn btn-std'
        style={ { marginTop: '30px' } }
        onClick={ saveAllSignatures }>Signer</button>
    </div>
  );
};

export default SignatureComponent;
