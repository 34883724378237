
import React, { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';

import './index.css';
import CameraComponent from '../../camera';
import { MbrType } from '../../../models/mbr';
import { ImgCommentPropType } from '../../../models/ctrlProp';

interface CommentDialProps {
  mbr: MbrType,
  txt?: string;
  img?: ImgCommentPropType;
  onValidComment: ( txt: string, img: ImgCommentPropType ) => void;
  onSupprComment: () => void;
  onAnnulComment: () => void;
};

const CommentDial: FunctionComponent<CommentDialProps> = ( { mbr, txt, img, onValidComment, onSupprComment, onAnnulComment } ) => {
  const [txtComponent, setTxtComponent] = useState('');
  const [imgComponent, setImgComponent] = useState<ImgCommentPropType | null>(null);
  const [isModified, setIsModified] = useState(false);
  const [showCamera, setShowCamera] = useState(false);

  useEffect(() => {
    if( txt !== txtComponent ) {
      setIsModified(true);
    }
  }, [txtComponent]);
  useEffect(() => {
    if( img !== imgComponent ) {
      setIsModified(true);
    }
  }, [imgComponent]);

  useEffect(() => {
    if( txt !== undefined ) {
      setTxtComponent(txt);
    }
    if( ( img !== undefined ) && ( img.id > 0 ) ) {
      setImgComponent(img);
      setShowCamera(true);
    }
  }, []);
  
  const handelChangeTxt = ( e: ChangeEvent<HTMLTextAreaElement> ) => {
    if( e.target.value !== txt ) {
      setTxtComponent(e.target.value);
      setIsModified(true);
    }
  };
  const handelClearTxt = () => {
    if( txt !== '' ) {
      setIsModified(true);
    }

    setTxtComponent('');
  };
  const handelValidComment = () => {
    if( isModified ) {
      const newImg:ImgCommentPropType = ( imgComponent !== null ) ? imgComponent : { id: 0, txt: '' };
      
      onValidComment(txtComponent, newImg);
    } else {
      onAnnulComment();
    }
  };
  const handelSupprComment = () => {
    onSupprComment();
  }
  const handelAnnulComment = () => {
    onAnnulComment();
  }
  const handelShowCamera = () => {  
    setShowCamera(true);
  };
  const handelUpdateImg = ( v: ImgCommentPropType | null ) => {
    if( img !== v ) {
      if( v === null ) {
        const newImg: ImgCommentPropType = {
          id: 0,
          txt: '',
        };

        setImgComponent(newImg);
      } else {
        setImgComponent(v);
      }

      setIsModified(true);
    }
  };



  return (
    <div className='comment-container'>
      <textarea className='text-input-comment' onChange={ handelChangeTxt } value={ txtComponent }/>
      <button className='btn-clear-comment btn btn-std' onClick={ handelClearTxt }>Vider</button>
      <br/>

      { ( showCamera ) &&
        <CameraComponent
          mbr={ mbr }
          img={ img }
          onCapture={ handelUpdateImg }
        />
      }
      { ( !showCamera ) &&
        <button className='btn-show-capture btn btn-std' onClick={ handelShowCamera } >PRISE DE VUE</button>
      }

      <div className='cont-btn-comment'>
        <button className='btn btn-std' onClick={ handelValidComment }>VALIDER</button>
        <button className='btn btn-std' onClick={ handelSupprComment }>SUPPRIMER</button>
        <button className='btn btn-std' onClick={ handelAnnulComment }>ANNULER</button>
      </div>
    </div>
  );
};

export default CommentDial;
