
import React, { FunctionComponent, useState, ChangeEvent, KeyboardEvent } from "react";
import 'react-tooltip/dist/react-tooltip.css';

import './index.css';

import { MbrType } from "../../models/mbr";
import { fetchSearchRsd } from "../../services/getSearch";
import { EntryType, mapToEntryType } from "../../models/entry";
import BtnEntry from "./btnEntry";

interface SearchEntryProps {
  mbr: MbrType;
  onEntrySelected: ( agc: number, grp: number, rsd: number, entry: number ) => void;
  debugg?: boolean;
};

type ElementListType = {
  agc: number,
  grp: number,
  rsd: number,
  entry: number,
  txt: string,
  tip: string,
}

const SearchEntryComponent: FunctionComponent<SearchEntryProps> = ( { mbr, onEntrySelected, debugg } ) => {
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [listResult, setListResult] = useState<EntryType[]>([]);
  const [showListResult, setShowListResult] = useState(false);

  const handleInputChanged = ( e: ChangeEvent<HTMLInputElement> ) => {
    setQuery(e.target.value);
  };
  const handleSearch = () => {
    const fetchData = async () => {
      try{
        const response = await fetchSearchRsd(mbr.id, mbr.mac, query);

        if( response.result ) {
          const newListe = mapToEntryType(response.data);

          setListResult(newListe)
          setLoading(false);
          setShowListResult(true);
        } else {
          setLoading(false);
        }
      } catch( error ) {
        console.error("handleSearch::ERROR", error);
      }
    };

    if( query !== '' ) {
      setLoading(true);
      fetchData();
    }
  };
  const handleEntrySelected = (item: EntryType) => {
    setShowListResult(false);
    onEntrySelected(item.agence.id, item.groupement.id, item.residence.id, item.id);
  };
  const handleKeyDown = ( e: KeyboardEvent<HTMLInputElement> ) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="search-entry-component">
      <div className="container-champs-search">
        <input
          type="text"
          value={ query }
          onChange={ handleInputChanged }
          onKeyDown={ handleKeyDown }
          placeholder="Rechercher..."
          className="search-input"
        />

        <button onClick={ handleSearch } className="search-icon-button">
          <img
            src="https://cdn-icons-png.flaticon.com/512/54/54481.png"
            alt="search"
            className="search-icon"
          />
        </button>
      </div>

      <div className="container-list-search">
        <div className="cont-list-result" style={ { display: ( showListResult ) ? 'block' : 'none' } }>
          {
            listResult.map(( item ) => (
              <button
                className="b_element_list btn btn-large btn-list"
                key={ item.id }
                onClick={ () => handleEntrySelected(item) }
              >
                <BtnEntry data={ item }/>
              </button>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default SearchEntryComponent;
