
import React, { FunctionComponent, useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
import { useHistory } from "react-router-dom";
import 'react-tooltip/dist/react-tooltip.css';

import './listSelect.css';

import { MbrType } from "../../models/mbr";
import { LIST_SELECT_TYPE_VIEW_AGENCE, LIST_SELECT_TYPE_VIEW_ENTRY, LIST_SELECT_TYPE_VIEW_GROUPEMENT, LIST_SELECT_TYPE_VIEW_RESIDENCE, SECRET_KEY } from "../../models/consts";
import { STORE_PARAM_ADRESSES, STORE_PARAM_GROUPEMENTS, STORE_PARAM_LIST_SELECT, STORE_PARAM_RESIDENCES } from "../../models/consts/store";
import { LOGIN_URL } from "../../models/consts/aplliUrls";

import fetchGetList from "../../services/getList";

interface ListSelectProps {
  mbr: MbrType;
  type: string;
  parent: number;
  val: number;
  onTouchMenu: () => void;
  onSetSelect: ( val: number ) => void;
};

type ElementListType = {
  id: number,
  txt: string,
  tip: string,
};

const ListSelect: FunctionComponent<ListSelectProps> = ( { mbr, type, parent, val, onTouchMenu, onSetSelect } ) => {
  const adrFile = '/components/listSelect/listSelect.tsx::ListSelect::';

  const [data, setData] = useState<ElementListType[]>([]);
  const [idParent, setIdParent] = useState(0);
  const [selected, setSelected] = useState(0);

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [showList, setShowList] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if( ( mbr.id > 0 ) && ( !isMounted || ( parent !== idParent ) ) ) {
      switch( type ) {
        case LIST_SELECT_TYPE_VIEW_AGENCE: 
          setTitle('Agence');
          fetchAgcs(); 
          break;
        case LIST_SELECT_TYPE_VIEW_GROUPEMENT: 
          setTitle('Groupement');
          fetchGrps(); 
          break;
        case LIST_SELECT_TYPE_VIEW_RESIDENCE: 
          setTitle('Résidence');
          fetchRsds(); 
          break;
        case LIST_SELECT_TYPE_VIEW_ENTRY: 
          setTitle('Entrée');
          fetchEntry(); 
          break;
      }
    }
    if( isMounted && ( val > 0 ) ) {
      selectItem();
    }
  }, [mbr, parent, val]);
  const fetchAgcs = () => {
    const encryptedData = localStorage.getItem(STORE_PARAM_LIST_SELECT);

    if( encryptedData !== null ) {
      const decryptedData = JSON.parse(CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(CryptoJS.enc.Utf8));
      const list: ElementListType[] = [];

      var mess = '';

      for( let x in decryptedData ) {
        const donn: ElementListType = {
          id: decryptedData[x].id,
          txt: decryptedData[x].txt,
          tip: decryptedData[x].txt,
        }

        list.push(donn);

        if( val === decryptedData[x].id ) {
          mess = decryptedData[x].txt;
        }
      }

      if( parent === 0 ) {
        setMessage('👉 Sélectionnez une agence');
      } else if( mess === '' ) {
        if( debuggComponent ) {
          console.error(adrFile+'ERROR::Agence inconnue');
        }

        setMessage('👉 Sélectionnez une agence');
      } else {
        if( debuggComponent ) {
          console.log(adrFile+'auto select '+type+' val', mess);
        }
      }
      
      setIdParent(parent);
      setData(list);
      setSelected(parent);
      setMessage(mess);

      selectItem(list);
    }

    setIsMounted(true);
  };
  const fetchGrps = async () => {
    if( parent > 0 ) {
      try {
        const param = parent.toString();
        const response = await fetchGetList(mbr.id, mbr.mac, STORE_PARAM_GROUPEMENTS, param);

        if( response.result ) {
          let list: ElementListType[] = [];

          for( let x in response.donn.list ) {
            const donn: ElementListType = {
              id: response.donn.list[x].id,
              txt: response.donn.list[x].txt,
              tip: response.donn.list[x].txt,
            }

            list.push(donn);
          }

          setIdParent(parent)
          setData(list);

          selectItem(list);
        } else {
          if( response.data.id === 1 ) {
            history.push(LOGIN_URL);
          }
        }
      } catch( error ) {
        console.error(adrFile+'fetchGrps::ERROR', error);
      }
    } else {
      setMessage('👉 Sélectionnez une agence');
    }

    setIsMounted(true);
  };
  const fetchRsds = async () => {
    if( parent > 0 ) {
      try {
        const param = parent.toString();
        const response = await fetchGetList(mbr.id, mbr.mac, STORE_PARAM_RESIDENCES, param);

        if( response.result ) {
          let list: ElementListType[] = [];

          for( let x in response.donn.list ) {
            const donn = {
              id: response.donn.list[x].id,
              txt: response.donn.list[x].txt,
              tip: response.donn.list[x].txt,
            }

            list.push(donn);
          }

          setIdParent(parent);
          setData(list);

          selectItem(list);
        } else {
          if( response.data.id === 1 ) {
            history.push(LOGIN_URL);
          }
        }

        setIsMounted(true);
      } catch(error) {
        console.error(adrFile+'fetchRsds::ERROR', error);
      }
    } else {
      setMessage('👉 Sélectionner un groupement');
    }
  };
  const fetchEntry = async () => {
    if( parent > 0 ) {
      try {
        const param = parent.toString();
        const response = await fetchGetList(mbr.id, mbr.mac, STORE_PARAM_ADRESSES, param);

        if( response.result ) {
          let list = [];

          for( let x in response.donn.list ) {
            const donn = {
              id: response.donn.list[x].id,
              txt: response.donn.list[x].txt,
              tip: response.donn.list[x].last,
            }

            list.push(donn);
          }

          setIdParent(parent);
          setData(list);

          selectItem(list);
        } else {
          if( response.data.id === 1 ) {
            history.push(LOGIN_URL);
          }
        }

        setIsMounted(true);
      } catch(error) {
        console.error(adrFile+'fetchEntry::ERROR', error);
      }
    } else {
      setMessage('👉 Sélectionner une résidence');
    }
  };
  const selectItem = (l?: ElementListType[]) => {
    if( isMounted || ( l !== undefined ) ) {
      var mess = '👉 Sélectionner un';

      switch( type ) {
        case LIST_SELECT_TYPE_VIEW_AGENCE: 
          mess += 'e agence';
          break;
        case LIST_SELECT_TYPE_VIEW_GROUPEMENT: 
          mess += ' groupement';
          break;
        case LIST_SELECT_TYPE_VIEW_RESIDENCE: 
          mess += 'e résidence';
          break;
        case LIST_SELECT_TYPE_VIEW_ENTRY: 
          mess += 'e entrée';
          break;
      }

      if( val > 0 ) {
        if( ( parent > 0 ) && ( val > 0 ) ) {
          if( l !== undefined ) {
            l.forEach(item => {
              if( item.id === val ) {
                mess = item.txt;
              }
            });
          } else {
            data.forEach(item => {
              if( item.id === val ) {
                mess = item.txt;
              }
            });
          }
        }
      }

      setSelected(val);
      setMessage(mess);
    }
  };
  const toggleList = () => {
    setShowList(!showList);
    onTouchMenu();
  };
  const handelClick = (id: number) => {
    setShowList(false);
    onTouchMenu();

    if( selected !== id ) {
      onSetSelect(id);
    }
  };

  return (
    <div className="list-select-component">
      <div className="card-panel grey lighten-5 full-width">
        <div className="flex-container" onClick={ toggleList } >
          <p>{ title }</p>
          <p className="right-arrow">{ `>` }</p>
        </div>
      </div>

      <div style={ ( showList ) ? { display: 'none' } : { display: 'block' } } onClick={ toggleList }>{ message }</div>

      <div className="cont-list-data" style={ ( showList ) ? { display: 'block' } : { display: 'none' } }>
        { data.map((donnee) => (
          <button 
            className="b_element_list btn btn-large btn-list" 
            key={ donnee.id } 
            onClick={ () => handelClick(donnee.id) }
            title={ ( type === LIST_SELECT_TYPE_VIEW_ENTRY ) ? `Dernier contrôle : ${donnee.tip}` : donnee.tip }
          >
            { donnee.txt }
          </button>
        )) }
      </div>
    </div>
  );
};

export default ListSelect;
