
import React, { FunctionComponent, useEffect, useState } from "react";
import CryptoJS from 'crypto-js';

import './grilleControl.css';
import logo from '../../assets/logo_appli.png';

import { SECRET_KEY } from "../../models/consts";
import { CONFIG_PROP_TYPE_CTRL, CONFIG_PROP_TYPE_LEVEE, CONFIG_PROP_VERSION_COMPLET, MENU_ONGLET_BUTTON_CONFIG, MENU_ONGLET_BUTTON_PLAN, MENU_ONGLET_BUTTON_SEND, MENU_ONGLET_BUTTON_SIGNATURE, MENU_ONGLET_HIDDEN, NOTE_VEIW_CONTROL_LAST, NOTE_VEIW_CONTROL_MOYENNE, NOTE_VEIW_CONTROL_NOTE, NOTE_VIEW_INFO_LAST, NOTE_VIEW_INFO_MOYENNE } from "../../models/consts/ctrlProp";
import { STORE_PARAM_CONF_PROP, STORE_PARAM_GRILLE_PROP, STORE_PARAM_LIST_SAVES } from "../../models/consts/store";
import { MbrType } from "../../models/mbr";
import { ConfigPropType, converteToGrilleTypeFromJson, convertGrillePropTypeToJson, convertToGrillePropType, convertToPlanActionsType, convertToPrestatairePropType, GrillePropType, initialConfigProp, initialGrilleProp, MakeGrilleCtrlProp, PlanActionsType, PrestataireCtrlPropType, ZoneGrillePropType } from "../../models/ctrlProp";
import { mapToGrillePropType } from "../../models/ctrlProp";
import { convertToEntryType, EntryType, initialEntry } from "../../models/entry";
import { AgentType, initialAgent, mapListAgents } from "../../models/agent";
import { ConfigSocPropType, convertToConfigSocPropType, initialConfigSocProp } from "../../models/configSocProp";

import { fetchGetEntry, fetchGetGrille, fetchGetRsdPlan } from "../../services/getRsd";

import IdResidComponent from "../idResid/idResid";
import ConfigPropComponent from "./configProp/configProp";
import LocalisationsCtrlProp from "./localisations";
import NoteComponent from "./notes";
import MenuOngletComponent from "../menu/onglet";
import { VALIDATION_VIEW_SELECTED } from "../../models/validations";
import SendRapportComponent from "../sendRapport";
import PlanActionsComponent from "./planActions";
import { fetchSetCtrlProp } from "../../services/setCtrlProp";
import SignatureComponent from "../signature/signature";
import { useNotification } from "../notify";
import { buildAssociativeArray } from "../../helpers/build-associative-array";

interface GrilleControlProps {
  mbr: MbrType;
  id: number;
  menuOpen: boolean;
};

const GrilleControlComponent: FunctionComponent<GrilleControlProps> = ( { mbr, id, menuOpen } ) => {
  const notify = useNotification();

  console.warn(adrFile+'START');

  const [structure, setStructure] = useState<GrillePropType>({ ...initialGrilleProp, grille: [...initialGrilleProp.grille] });

  const [fiche, setFiche] = useState<EntryType>({ ...initialEntry });
  const [grille, setGrille] = useState<GrillePropType>({ ...initialGrilleProp, grille: [...initialGrilleProp.grille] });
  const [noteCtrl, setNoteCtrl] = useState<number | null>(null);
  
  const [listPrestataires, setListPrestataires] = useState<AgentType[]>([]);
  const [listAgents, setListAgents] = useState<AgentType[]>([]);
  
  const [presta, setPresta] = useState<AgentType>({ ...initialAgent });
  const [configSocProp, setConfigSocProp] = useState<ConfigSocPropType>({ ...initialConfigSocProp });

  const [isSigned, setIsSigned] = useState(false);
  const [isMonted, setIsMonted] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  
  const [showConfigBlock, setShowConfigBlock] = useState(true);
  const [showMailBlock, setShowMailBlock] = useState(false);
  const [showPlanActionsBlock, setShowPlanActionsBlock] = useState(false);
  const [showSignatures, setShowSignature] = useState(false);
  const [menuOngletViewSelected, setMenuOngletViewSelected] = useState(MENU_ONGLET_BUTTON_CONFIG);

  const [showLogo, setShowLogo] = useState(true);
  const [showGrille, setShowGrille] = useState(false);
  const [showSmallNotes, setShowSmallNotes] = useState(true);
  const [showNotes, setShowNotes] = useState(false);
  const [materializeClassGrille, setMaterializeClassGrille] = useState('s12 m12 l8');
  
  useEffect(() => {
    if( isMonted && isStarted ) {
      saveCtrl();
    }
  }, [menuOpen]);
  useEffect(() => {
    if( ( mbr.id > 0 ) && ( id > 0 ) && !isMonted ) {
      fetchData();
    }
  }, []);

  const handelSelectPresta = ( val: AgentType ) => {
    setPresta(val);
  };
  const handelTypeChange = ( val: string ) => {
    if( grille !== null ) {
      let newGrille = {
        ...grille,
        type: val,
      };

      setGrille(newGrille);
      protectCtrl(newGrille);

      if( val === CONFIG_PROP_TYPE_CTRL ) {
        if( grille.version === '' ) {
          hideGrille();
        } else {
          showSmallGrill();
        }
      } else if( val === CONFIG_PROP_TYPE_LEVEE ) {
        if( grille.plan && ( grille.plan.id > 0 ) ) {
          selectOngletView(MENU_ONGLET_BUTTON_PLAN);
        } else {
          notify("Aucun plan d'actions enregistré", { variant: 'info' });
        }

        hideGrille();
      } else {
        showSmallGrill();
      }
    }
  };
  const handelVersionChange = ( val: string ) => {
    let newGrille = {
      ...grille,
      version: val,
    };

    setGrille(newGrille);
    protectCtrl(newGrille);

    showSmallGrill();
  };
  const handelConfigChange = ( val: ConfigPropType ) => {
    let newGrille = {
      ...grille,
      config: val,
    };

    setGrille(newGrille);
    protectCtrl(newGrille);
  };
  const handelGrilleClicked = () => {
    showBigGrille();
  };
  const handelChangePlan = ( plan: PlanActionsType ) => {
    let newGrille = {
      ...grille,
      plan: plan,
    };
    setGrille(newGrille);
    protectCtrl(newGrille);
    saveCtrl(newGrille);
  }
  const fetchData = async () => {
    const encryptedDataCible = localStorage.getItem(STORE_PARAM_GRILLE_PROP);
    const encryptedDataConfSocProp = localStorage.getItem(STORE_PARAM_CONF_PROP);

    if( ( encryptedDataCible !== null ) && ( encryptedDataConfSocProp !== null ) ) {
      const decryptedDataCible = JSON.parse(CryptoJS.AES.decrypt(encryptedDataCible, SECRET_KEY).toString(CryptoJS.enc.Utf8));
      const decryptedDataConfSocProp = JSON.parse(CryptoJS.AES.decrypt(encryptedDataConfSocProp, SECRET_KEY).toString(CryptoJS.enc.Utf8));

      const newStruct = mapToGrillePropType(decryptedDataCible);
      const newConfigSocProp = convertToConfigSocPropType(decryptedDataConfSocProp);

      setStructure(newStruct);
      setConfigSocProp(newConfigSocProp);
      setGrille(prevState => ({
        ...prevState,
        controleur: mbr.idAgent,
      }));
      
      fetchEntry(newStruct);
    }
  };
  const fetchEntry = async ( structPassed: GrillePropType ) => {
    try {
      const response = await fetchGetEntry(mbr.id, mbr.mac, id.toString());

      if( response.result ) {
        const newFiche = convertToEntryType(response.data);
        const newListPrestataires = mapListAgents(response.data.list.presta);
        const newListAgents = mapListAgents(response.data.list.agt);
        const prest = convertToPrestatairePropType(response.data.agt);

        setGrille(prevState => ({
          ...prevState,
          rsd: response.data.id,
          presta: prest,
        }));
        setListPrestataires(newListPrestataires);
        setListAgents(newListAgents);
        setFiche(newFiche);

        if( response.data.agt.typ === 'p' ) {
          for( let x in newListPrestataires ) {
            if( newListPrestataires[x].id === response.data.agt.id ) {
              setPresta(newListPrestataires[x]);
            }
          }
        } else {
          for( let x in newListAgents ) {
            if( newListAgents[x].id === response.data.agt.id ) {
              setPresta(newListAgents[x]);
            }
          }
        }
        
        fetchGrille(newFiche.id, prest, response.data.date, structPassed);
      } else {
        console.error(adrFile+'fetchEntry::response::ERROR', response.data.txt);
      }
    } catch(error) {
      console.error(adrFile+'fetchEntry::ERROR', error);
    }
  };
  const fetchGrille = async ( idPassed: number, prestaPassed: PrestataireCtrlPropType, datePassed: number, structPassed: GrillePropType ) => {
    try {
      let listSaves = localStorage.getItem(STORE_PARAM_LIST_SAVES);
      let b = false;
      let jsonGrille = null;

      if( listSaves !== null ) {
        let t = listSaves.split(',').map(item => parseInt(item.trim(), 10));

        for( let i = 0, c = t.length; i < c; i++ ) {
          if( t[i] > 0 ) {
            const encryptedGgrille = localStorage.getItem('prop_'+idPassed.toString());

            if( encryptedGgrille !== null ) {
              let jsonEncryptedGrille = JSON.parse(encryptedGgrille);

              if( listSaves !== '' ) listSaves += ',';
              listSaves += t[i];

              if( jsonEncryptedGrille.rsd === idPassed ) {
                if( ( 'date' in jsonEncryptedGrille ) && ( parseInt(jsonEncryptedGrille.date) === datePassed ) && ( !( 'signatures' in jsonEncryptedGrille ) || ( jsonEncryptedGrille.signatures === '' ) ) ) {
                  jsonGrille = jsonEncryptedGrille;
                  b = true;
                } else if( ( 'date' in jsonEncryptedGrille ) && ( jsonEncryptedGrille.date !== datePassed ) ) {
                  if( ( 'saved' in jsonEncryptedGrille ) && !jsonEncryptedGrille.saved ) {
                    const convertedEncryptedGrille = converteToGrilleTypeFromJson(jsonEncryptedGrille);

                    saveCtrl(convertedEncryptedGrille, true);
                  }
                }
              } else {
                if( ( 'saved' in jsonEncryptedGrille ) && !jsonEncryptedGrille.saved ) {
                  const convertedEncryptedGrille = converteToGrilleTypeFromJson(jsonEncryptedGrille);

                  saveCtrl(convertedEncryptedGrille, true);
                }
              }
            }
          }
        }
      }

      if( b ) {
        const savedGrille = converteToGrilleTypeFromJson(jsonGrille);
          
        setGrille(savedGrille);
        setNoteCtrl(( ( savedGrille.note === undefined ) ? null : savedGrille.note ));
        setIsMonted(true);

        if( ( 'saved' in jsonGrille ) && ( jsonGrille.saved === false ) ) {
          saveCtrl(savedGrille);
        }
        if( ( 'note' in jsonGrille ) && ( jsonGrille.note !== null ) ) {
          setIsStarted(true);
        }

        const response = await fetchGetRsdPlan(mbr.id, mbr.mac, idPassed);

        if( ( 'result' in response ) && response.result ) {
          const newPlan = convertToPlanActionsType(response.data);

          setGrille(prevState => ({
            ...prevState,
            plan: newPlan,
          }));
        } else {
          console.error(adrFile+'fetchGrille::response::ERROR', response.data.txt);
        }
      }
      if( !b ) {
        const response = await fetchGetGrille(mbr.id, mbr.mac, idPassed);

        if( response.result ) {
          jsonGrille = response.data;

          const receivedGrille = convertToGrillePropType(jsonGrille, prestaPassed);
          const newGrille = MakeGrilleCtrlProp(buildAssociativeArray(structPassed.grille), receivedGrille);

          jsonGrille = convertGrillePropTypeToJson(newGrille);

          setGrille(newGrille);
          setNoteCtrl(( ( newGrille.note === undefined ) ? null : newGrille.note ));
          setIsMonted(true);
          protectCtrl(newGrille);

          if( newGrille.note && ( newGrille.note !== null ) ) {
            setIsStarted(true);
          }
        } else {
          console.error(adrFile+'fetchGrille::response::ERROR', response.data.txt);
        }
      }

      jsonGrille.saved = true;

      localStorage.setItem('prop_'+idPassed.toString(), JSON.stringify(jsonGrille));
    } catch(error) {
      console.error(adrFile+'fetchGrille::ERROR', error);
    }
  };

  const selectOngletView = (v: string) => {
    if( VALIDATION_VIEW_SELECTED.includes(v) ) {
      var b = false;

      saveCtrl();

      if( v === MENU_ONGLET_BUTTON_CONFIG ) {
        setShowMailBlock(false);
        setShowPlanActionsBlock(false);
        setShowSignature(false);
        setShowConfigBlock(true);
        b = true;
      } else if( v === MENU_ONGLET_BUTTON_SEND ) {
        setShowConfigBlock(false);
        setShowPlanActionsBlock(false);
        setShowSignature(false);
        setShowMailBlock(true);
        b = true;
      } else if( v === MENU_ONGLET_BUTTON_PLAN ) {
        if( ( ( grille.plan !== undefined ) && ( grille.plan.id > 0 ) ) ||  isStarted ) {
          setShowConfigBlock(false);
          setShowMailBlock(false);
          setShowSignature(false);
          setShowPlanActionsBlock(true);
          b = true;
        } else {
          notify("Vous devez commencer votre contrôle avant d'établir un plan d'actions", { variant: 'info' });
        }
      } else if( v === MENU_ONGLET_BUTTON_SIGNATURE ) {
        if( isStarted && !isSigned ) {
          setShowConfigBlock(false);
          setShowMailBlock(false);
          setShowPlanActionsBlock(false);
          setShowSignature(true);
          b = true;
        } else {
          notify('Vous devez avoir commencer votre contrôle avant de le signer !', { variant: 'info' });
        }
      } else {
        hideParams();
      }

      if( b ) {
        if( showNotes ) {
          setShowNotes(false);
          setShowSmallNotes(true);
        }
        if( grille.type && ( grille.type !== CONFIG_PROP_TYPE_LEVEE ) ) {
          showSmallGrill();
        }

        setMaterializeClassGrille('s12 m12 l8');
        setMenuOngletViewSelected(v);
      }
    }
  }
  const setGrilleChangement = ( g: ZoneGrillePropType[], n: number | null ) => {
    const newGrille = {
      ...grille,
      grille: g,
      note: n,
    };

    setIsStarted(true);
    setNoteCtrl(n);
    setGrille(newGrille);
    protectCtrl(newGrille);
    handelGrilleClicked();
  };

  const protectCtrl = ( gr?: GrillePropType ) => {
    const grilleToUse = ( gr === undefined ) ? grille : gr;

    if( ( grilleToUse.rsd !== null ) && ( grilleToUse.rsd !== undefined ) ) {
      const listSavesFromStorage = localStorage.getItem(STORE_PARAM_LIST_SAVES);
      let listSaves = '';
      let rsdToAdd = grilleToUse.rsd.toString();
      let i_rsd = 0;

      if( listSavesFromStorage !== null ) {
        let t = listSavesFromStorage.split(',');

        for( let i = 0, c = t.length; i < c; i++ ) {
          if( parseInt(t[i]) > 0 ) {
            if( t[i].split('_')[0] === rsdToAdd ) {
              let encryptedRsdSaved = localStorage.getItem(('prop_'+rsdToAdd));

              if( encryptedRsdSaved !== null ) {
                let rsdSaved = JSON.parse(encryptedRsdSaved);

                if( ( parseInt(rsdSaved.date) !== grille.date ) && ( rsdSaved.note !== null ) && !rsdSaved.saved ) {
                  i_rsd++;
                  rsdToAdd = grille.rsd + '_' + i_rsd.toString();
                  
                  if( listSaves !== '' ) listSaves += ',';
                  listSaves += t[i];
                }
              }
            } else {
              if( listSaves !== '' ) listSaves += ',';
              listSaves += t[i];
            }
          }
        }
      }

      if( listSaves !== '' ) listSaves += ',';
      listSaves += rsdToAdd;

      const newGrille = convertGrillePropTypeToJson(grilleToUse);

      if( newGrille !== null ) {
        newGrille.saved = false;
      }

      const jsonGrille = JSON.stringify(newGrille);

      let m = 'prop_' + rsdToAdd;

      localStorage.setItem(m, jsonGrille);
      localStorage.setItem(STORE_PARAM_LIST_SAVES, listSaves);

      if( newGrille && ( newGrille.note !== undefined ) && ( newGrille.note !== null ) ) {
        setIsStarted(true);
      }
    }
  };
  const saveCtrl = async ( grillePassed?: GrillePropType, old?: boolean ) => {
    const grilleToSave = ( grillePassed !== undefined ) ? grillePassed : grille;

    if( ( grilleToSave.rsd !== null )  && ( grilleToSave.rsd !== undefined ) ) {
      if( ( grilleToSave.note !== null ) || ( grilleToSave.id && ( grilleToSave.id > 0 ) ) ) {
        try{
          const newGrille = convertGrillePropTypeToJson(grilleToSave, true);
          const jsonGrille = JSON.stringify(newGrille);
          const response = await fetchSetCtrlProp(mbr.id, mbr.mac, jsonGrille);

          if( ( response.result !== undefined ) && response.result ) {
            var m = 'prop_' + grilleToSave.rsd;

            if( old ) {
              localStorage.removeItem(m);
            } else {
              const encryptedGrilleSaved = localStorage.getItem(m);

              if( encryptedGrilleSaved !== null ) {
                const grilleSaved = JSON.parse(encryptedGrilleSaved);

                grilleSaved.saved = true;
                grilleSaved.id = response.data.id;

                localStorage.setItem(m, JSON.stringify(grilleSaved));

                if( newGrille && ( newGrille.rsd !== undefined ) && ( newGrille.rsd === grilleSaved.rsd ) ) {
                  setGrille(prevState => ({
                    ...prevState,
                    id: response.data.id,
                  }));
                }

                if( newGrille && ( newGrille.note !== undefined ) && ( newGrille.note !== null ) ) {
                  setIsStarted(true);
                }
              }
            }
          } else if( response.data !== undefined ) {
            console.error(adrFile+'saveCtrl::ERROR', response.data.txt);
          } else {
            console.error(adrFile+'saveCtrl::ERROR == réponse non valide');
          }
        } catch( error ) {
          console.error(adrFile+'saveCtrl::ERROR', error);
        }
      }
    }
  };
  const saveSignatures = async ( sigs: string[] ) => {
    if( grille.id && ( grille.id > 0 ) ) {
      const newGrille = { 
        ...grille,
        signatures: sigs,
      }
      setGrille(newGrille);
      setIsSigned(true);
      saveCtrl(newGrille);
    }
  };

  const showSmallGrill = () => {
    hideLogo();
    setShowSmallNotes(true);
    setShowNotes(false);
    setMaterializeClassGrille('s12 m12 l8');
  };
  const showBigGrille = () => {
    hideLogo();
    hideParams();
    setShowSmallNotes(false);
    setShowNotes(true);
    setMaterializeClassGrille('s12 m12 l10');
    setMenuOngletViewSelected(MENU_ONGLET_HIDDEN);
  };
  const hideLogo = () => {
    setShowLogo(false);
    setShowGrille(true);
  };
  const hideGrille = () => {
    setShowGrille(false);
    setShowNotes(false);
    setShowSmallNotes(true);
    setShowLogo(true);
  };
  const hideParams = () => {
    setShowConfigBlock(false);
    setShowMailBlock(false);
    setShowPlanActionsBlock(false);
    setShowSignature(false);
    setMaterializeClassGrille('s12 m12 l10');
    setMenuOngletViewSelected(MENU_ONGLET_HIDDEN);
  };

  return (
    <div className="grille-control-component row">
      <MenuOngletComponent view={ menuOngletViewSelected } onViewSelected={ selectOngletView }/>

      <div className="block-config col s12 m12 l4" style={ { display: ( showConfigBlock ) ? "block" : "none" } }>
        <IdResidComponent
          mbr={ mbr } 
          fiche={ fiche } 
          listPrestataires={ listPrestataires } 
          listAgents={ listAgents }
          presta={ presta }
          onSelectedPresta={ handelSelectPresta }
        />
        <ConfigPropComponent
          version={ ( grille.version ) ? grille.version : CONFIG_PROP_VERSION_COMPLET }
          type={ ( grille.type ) ? grille.type : CONFIG_PROP_TYPE_CTRL }
          config={ ( grille.config ) ? grille.config : { ...initialConfigProp } }
          isModifiable={ isSigned }
          onVersionCtrlChange={ handelVersionChange }
          onTypeCtrlChange={ handelTypeChange }
          onConfigCtrlChange={ handelConfigChange }
        />
      </div>

      <div className="block-mail col s12 m12 l4" style={ { display: ( showMailBlock ) ? "block" : "none" } }>
        <SendRapportComponent/>
      </div>

      <div className="block-plan-actions col s12 m12 l4" style={ { display: ( showPlanActionsBlock ) ? "block" : "none" } }>
        <PlanActionsComponent
          mbr={ mbr }
          plan={ grille.plan }
          rsd={ fiche }
          ctrl={ grille }
          onPlanChanging={ handelChangePlan }
        />
      </div>

      <div className="block-signature col s12 m12 l4" style={ { display: ( showSignatures ) ? "block" : "none" } }>
        <SignatureComponent
          mbr={ mbr }
          idCtrl={ ( grille.id ) ? grille.id : 0 }
          numberOfSignatures={ 2 } 
          isSigned={ isSigned } 
          onSignature={ saveSignatures }
        />
      </div>

      <div className="block-notes col s12 m12 l2" style={ { display: ( showNotes ) ? "block" : "none" } }>
        <div className="ctrl-note-container card-panel grey lighten-5">
          <NoteComponent 
            lbl="" 
            val={ noteCtrl } 
            conf={ configSocProp } 
            parent={ NOTE_VEIW_CONTROL_NOTE }
          />
        </div>

        <div className="last-note-container card-panel grey lighten-5">
          <NoteComponent 
            lbl="Dernier contrôle" 
            val={ fiche.notesProp.last } 
            conf={ configSocProp } 
            parent={ NOTE_VEIW_CONTROL_LAST }
          />
        </div>

        <div className="moy-note-container card-panel grey lighten-5">
          <NoteComponent 
            lbl="Moyenne des contrôles" 
            val={ fiche.notesProp.moy } 
            conf={ configSocProp } 
            parent={ NOTE_VEIW_CONTROL_MOYENNE }
          />
        </div>
      </div>

      <div className="block-img col s12 m12 l8" style={ { display: ( showLogo ) ? "block" : "none" } }>
        <div className="notes-grille-container" style={ { display: ( showSmallNotes ) ? "block" : "none" } }>
          <div className="last-note-container">
            <NoteComponent
              lbl="Dernier contrôle"
              val={ fiche.notesProp.last }
              conf={ configSocProp } 
              parent={ NOTE_VIEW_INFO_LAST }
            />
          </div>

          <div className="moy-note-container">
            <NoteComponent 
              lbl="Moyenne des contrôles" 
              val={ fiche.notesProp.moy } 
              conf={ configSocProp } 
              parent={ NOTE_VIEW_INFO_MOYENNE }
            />
          </div>
        </div>

        <img src={ logo } alt="logo"/>
      </div>

      <div className={`block-grille col ${ materializeClassGrille }`} style={ { display: ( showGrille ) ? "block" : "none" } }>
        <div className="notes-grille-container" style={ { display: ( showSmallNotes ) ? "block" : "none" } }>
          <div className="last-note-container">
            <NoteComponent
              lbl="Dernier contrôle"
              val={ fiche.notesProp.last }
              conf={ configSocProp } 
              parent={ NOTE_VIEW_INFO_LAST }
            />
          </div>

          <div className="moy-note-container">
            <NoteComponent 
              lbl="Moyenne des contrôles" 
              val={ fiche.notesProp.moy } 
              conf={ configSocProp } 
              parent={ NOTE_VIEW_INFO_MOYENNE }
            />
          </div>
        </div>

        { ( isMonted && showGrille ) && 
          <LocalisationsCtrlProp
            mbr={ mbr }
            structure={ structure }
            grille={ grille }
            conf={ configSocProp }
            isSigned={ isSigned }
            onGrilleChange={ setGrilleChangement }
            onGrilleClicked={ handelGrilleClicked }
            onZoneChanging={ saveCtrl }
          />
        }
      </div>
    </div>
  )
};

export default GrilleControlComponent;
