
import { AgenceType, convertToAgenceType, initialAgence, ReceveidAgenceType } from "./agence";
import { ReceveidGroupementType, GroupementType, initialGroupement, convertToGroupememtType } from "./groupement";
import { ReceveidResidenceType, initialResidence, ResidenceType, convertToResidenceType } from "./residence";
import { ReceveidAgentType, AgentType, initialAgent, convertToAgentType } from "./agent";
import { AdresseType, convertToAdresseType, initialAdresse, ReceveidAdresseType } from "./adresse";

type RecevideListePrestaEntryType = {
  agt: ReceveidAgentType[],
  presta: ReceveidAgentType[],
};
type ReceveidReferentsEntryType = {
  encadrant: ReceveidAgentType,
  gardien: ReceveidAgentType,
  entretien: ReceveidAgentType,
};
type ReceveidNotesPropType = {
  last: number | null,
  moy: number | null,
};
export type ReceveidPrestaEntryType = {
  id: number,
  typ: string,
};
export type ReceveidEntryType = {
  id: number,
  txt: string,
  agence: ReceveidAgenceType,
  groupement: ReceveidGroupementType,
  residence: ReceveidResidenceType,
  ref: string,
  entree: string,
  adresse: ReceveidAdresseType,
  referents: ReceveidReferentsEntryType,
  agt: ReceveidPrestaEntryType,
  list: RecevideListePrestaEntryType,
  img: string,
  notesProp: ReceveidNotesPropType,
};
type ReferentsEntryType = {
  encadrant: AgentType,
  gardien: AgentType,
  entretien: AgentType,
};
type NotesPropEntryType = {
  last: number | null,
  moy: number | null,
};
export type PrestataireEntryType = {
  id: number,
  type: string,
};
export type EntryType = {
  id: number,
  txt: string,
  agence: AgenceType,
  groupement: GroupementType,
  residence: ResidenceType,
  ref: string,
  entry: string
  adresse: AdresseType,
  referents: ReferentsEntryType,
  img: string,
  notesProp: NotesPropEntryType,
};
const makeReferentEntry = ( data: ReceveidReferentsEntryType ): ReferentsEntryType => {
  return {
    encadrant: convertToAgentType(data.encadrant),
    gardien: convertToAgentType(data.gardien),
    entretien: convertToAgentType(data.entretien),
  }
}
const initialReferent: ReferentsEntryType = {
  encadrant: { ...initialAgent },
  gardien: { ...initialAgent },
  entretien: { ...initialAgent },
};
const initialNotesProp: NotesPropEntryType = {
  last: null,
  moy: null,
};
export const initialEntry: EntryType = {
  id: 0,
  txt: '',
  agence: { ...initialAgence },
  groupement: { ...initialGroupement },
  residence: { ...initialResidence },
  ref: '',
  entry: '',
  adresse: { ...initialAdresse },
  referents: { ...initialReferent },
  img: '',
  notesProp: { ...initialNotesProp },
};
export const convertToEntryType = ( data: ReceveidEntryType ): EntryType => {
  return {
    id: data.id,
    txt: data.txt,
    agence: convertToAgenceType(data.agence),
    groupement: convertToGroupememtType(data.groupement),
    residence: convertToResidenceType(data.residence),
    ref: data.ref,
    entry: data.entree,
    adresse: convertToAdresseType(data.adresse),
    referents: makeReferentEntry(data.referents),
    img: data.img,
    notesProp: convertToNotesPropType(data.notesProp),
  };
};
export const convertToPrestataireEntryType = ( data: ReceveidPrestaEntryType ): PrestataireEntryType => {
  return {
    id: data.id,
    type: data.typ,
  };
};
const convertToNotesPropType = ( data: ReceveidNotesPropType ): NotesPropEntryType => {
  return {
    last: data.last,
    moy: data.moy,
  }
}

export function mapToEntryType( receveidEntries: ReceveidEntryType[] ): EntryType[] {
  return Object.values(receveidEntries).map(( receveidEntry ) => {
    return {
      id: receveidEntry.id,
      txt: receveidEntry.txt,
      agence: convertToAgenceType(receveidEntry.agence),
      groupement: convertToGroupememtType(receveidEntry.groupement),
      residence: convertToResidenceType(receveidEntry.residence),
      ref: receveidEntry.ref,
      entry: receveidEntry.entree,
      adresse: convertToAdresseType(receveidEntry.adresse),
      referents: {
        encadrant: receveidEntry.referents.encadrant,
        gardien: receveidEntry.referents.gardien,
        entretien: receveidEntry.referents.entretien,
      },
      img: receveidEntry.img,
      notesProp: {
        last: receveidEntry.notesProp.last,
        moy: receveidEntry.notesProp.moy,
      },
    };
  });
}
