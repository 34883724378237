
import React, { FunctionComponent } from "react";

interface LineIdResidComponentProps {
  lbl: string;
  val: string;
};

const LineIdResidComponent: FunctionComponent<LineIdResidComponentProps> = ( { lbl, val } ) => {
  return (
    <div className="line_id_resid">
      <div className="lbl-line-id-resid" style={{display: 'inline-block'}}><b>{`${lbl}: `}</b></div>
      <div className="val-line-id-resid" style={{display: 'inline-block'}}>{val}</div>
    </div>
  );
};

export default LineIdResidComponent;
