
import React, { FunctionComponent, CSSProperties } from "react";
import CryptoJS from 'crypto-js';
import { useHistory } from "react-router-dom";

import './menu.css';

import { SECRET_KEY } from "../../models/consts";
import { CONFIGURATION_URL, CONTROL_PROP_URL, LOGIN_URL, SELECT_ENTRY_URL } from "../../models/consts/aplliUrls";
import { PARAM_LOGIN_DECO } from "../../models/consts/login";
import { STORE_PARAM_CIBLE } from "../../models/consts/store";
import { MbrType } from "../../models/mbr";

import fetchLogin from "../../services/login";
import { useNavigation } from "../navigationContext";

interface MenuProps {
  mbr: MbrType;
  show: boolean;
};

const menuButtonBaseStyle: CSSProperties = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  width: '50px',
  height: '50px',
  cursor: 'pointer',
  zIndex: 1001,
  transition: 'width 0.3s, height 0.3s, top 0.3s, right 0.3s, left 0.3s',
};
const menuButtonMediumStyle: CSSProperties = {
  width: '40px',
  height: '40px',
};
const menuButtonSmallStyle: CSSProperties = {
  top: '10px',
  left: '10px',
  right: 'auto',
  width: '45px',
  height: '45px',
};

export const Menu: FunctionComponent<MenuProps> = ( { mbr, show } ) => {
  const history = useHistory();
  const { previousPath } = useNavigation();

  const handelSave = () => {
//    console.log('save ctrl');
  };
  const handelChangeEntry = async () => {
    try {
      const encryptedDataCible = localStorage.getItem(STORE_PARAM_CIBLE);

      if( encryptedDataCible !== null ) {
        const decryptedDataCible = JSON.parse(CryptoJS.AES.decrypt(encryptedDataCible, SECRET_KEY).toString(CryptoJS.enc.Utf8));

        decryptedDataCible.entry = null;

        let jsonCible = JSON.stringify(decryptedDataCible);
        let encryptedData = CryptoJS.AES.encrypt(jsonCible, SECRET_KEY).toString();

        localStorage.setItem(STORE_PARAM_CIBLE, encryptedData);

        history.push(SELECT_ENTRY_URL);
      }
    } catch(error) {
      console.error('menu.tsx::handelChangeEntry::ERROR', error);
    }
  };
  const handelConfig = () => {
//    console.log('open config');
  };
  const handelTech = () => {
//    console.log('history push tech');
  };
  const handelPlanActions = () => {
//    console.log('open planActions');
  };
  const handelProfil = () => {
//    console.log('open profil');
  };
  const handelDeconexion = async () => {
    try {
      if( mbr.id > 0 ) {
        const response = await fetchLogin(mbr.pseudo, mbr.mdp, mbr.mac, PARAM_LOGIN_DECO);

        if( response.result ) {
          history.push(LOGIN_URL);
        } else {
          if( response.data.id === 1 ) {
            history.push(LOGIN_URL);
          }
        }
      }
    } catch(error) {
      console.log('menu.tsx::handelDeconexion::ERROR', error);
    }
  };

  return (
    <div className={`menu-component ${ ( show ) ? ' visible-style' : '' }`}>
      {( previousPath === CONTROL_PROP_URL ) && <div className="btn-menu" onClick={handelSave}>Enregistrer le contrôle</div>}
      <div className="btn-menu" onClick={handelChangeEntry}>Changer d'entrée</div>
      <div className="lgn-menu"></div>
      {( previousPath !== CONFIGURATION_URL ) && <div className="btn-menu" onClick={handelConfig}>Configuration du contrôle</div>}
      <div className="btn-menu" onClick={handelTech}>Désordre technique</div>
      <div className="btn-menu" onClick={handelPlanActions}>Plan d'actions</div>
      <div className="lgn-menu"></div>
      <div className="btn-menu" onClick={handelProfil}>Profil</div>
      <div className="btn-menu" onClick={handelDeconexion}>Déconnexion</div>
    </div>
  );
};

export const getMenuButtonStyle = (): CSSProperties => {
  const width = window.innerWidth;

  if (width >= 600 && width <= 992) {
    return { ...menuButtonBaseStyle, ...menuButtonMediumStyle };
  } else if (width < 600) {
    return { ...menuButtonBaseStyle, ...menuButtonSmallStyle };
  } else {
    return menuButtonBaseStyle;
  }
};
