
import React, { FunctionComponent } from "react";
import 'react-tooltip/dist/react-tooltip.css';

import './btnEntry.css';

import { EntryType } from "../../models/entry";

interface BtnEntryProps {
  data: EntryType;
  debugg?: boolean;
};

const BtnEntry: FunctionComponent<BtnEntryProps> = ( { data, debugg } ) => {
  return (
    <div className="btn-entry-component">
      <div className="ref-btn-entry">
        { data.ref }
      </div>
      <div className="txt-btn-entry">
        { data.txt }
      </div>
      <div className="entree-btn-entry">
        { `Entrée ${data.entry}` }
      </div>
      <div className="adr-btn-entry">
        { data.adresse.adr + ' ' + data.adresse.ville }
      </div>
    </div>
  )
};

export default BtnEntry;
