
import React, { FunctionComponent, useEffect, useState } from "react";

import './index.css';

import { CriterGrillePropType, ElementGrillePropType, GrillePropType, MakeGrilleCtrlProp, ZoneGrillePropType } from "../../../models/ctrlProp";
import { AssociativeArrayType, buildAssociativeArray } from "../../../helpers/build-associative-array";
import { ConfigSocPropType } from "../../../models/configSocProp";
import ZonePropViewComponent from "./zone";
import { ZONE_TYPE_CONTRAT, ZONE_TYPE_PROXI } from "../../../models/consts/residence";
import { MbrType } from "../../../models/mbr";

interface LocalisationsCtrlPropProps {
  mbr: MbrType,
  structure: GrillePropType;
  grille: GrillePropType;
  conf: ConfigSocPropType;
  isSigned: boolean;
  onGrilleChange: ( grille: ZoneGrillePropType[], note: number|null ) => void;
  onGrilleClicked: () => void;
  onZoneChanging: () => void;
};

const LocalisationsCtrlProp: FunctionComponent<LocalisationsCtrlPropProps> = ( { 
    mbr,
    structure,
    grille,
    conf,
    isSigned,
    onGrilleChange,
    onGrilleClicked,
    onZoneChanging,
 } ) => {
  const [hasStructure, setHasStructure] = useState(false);
  const [hasGrille, setHasGrille] = useState(false);
  const [structureComponent, setStructureComponent] = useState<AssociativeArrayType>({});
  const [grilleComponent, setGrilleComponent] = useState<ZoneGrillePropType[]>([]);

  const [grilleCtrl, setGrilleCtrl] = useState<ZoneGrillePropType[]>([]);
  const [noteCtrl, setNoteCtrl] = useState<number | null>(null);

  const [showGrilleProxi, setShowGrilleProxi] = useState(true);
  const [showGrilleContrat, setShowGrilleContrat] = useState(true);
  
  const [zoneSelected, setZoneSelected] = useState(0);
  const [isModifiable, setIsModifiable] = useState(true);
  const [isMonted, setIsMonted] = useState(false);
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    if( isMonted && isStarted && isModifiable ) {
      onGrilleChange(grilleCtrl, noteCtrl);
    }
  }, [grilleCtrl]);
  useEffect(() => {
    setIsModifiable(!isSigned);
  }, [isSigned]);

  useEffect(() => {
    const struct = buildAssociativeArray(structure.grille);

    setStructureComponent(struct);
    setHasStructure(true);

    if( grille.rsd ) {
      let newGrille = MakeGrilleCtrlProp(struct, grille, true);

      setGrilleComponent(grille.grille);
      setGrilleCtrl(newGrille.grille);
      setHasGrille(true);
      setIsMonted(true);
    }
  }, []);

  const handelShowProxi = () => {
    setShowGrilleProxi(true);
    setShowGrilleContrat(false);
    onGrilleClicked();
  };
  const handelShowContrat = () => {
    setShowGrilleProxi(false);
    setShowGrilleContrat(true);
    onGrilleClicked();
  };
  const handelShowAll = () => {
    setShowGrilleProxi(true);
    setShowGrilleContrat(true);
    onGrilleClicked();
  };

  const selectZone = ( i: number ) => {
    setZoneSelected(i);

    if( isStarted ) {
      onZoneChanging();
    }
  };
  const setGrilleChanged = ( z: number, e: ElementGrillePropType[] ) => {
    setIsStarted(true);

    let meteo = ( grille.config ) ? ( ( grille.config.meteo ) ? 1.1 : 1 ) : 1;
    let maxCtrl = 0;
    let noteCtrl = 0;

    var elements: ElementGrillePropType[] = [];

    const newGrille: ZoneGrillePropType[] = Object.values(grilleCtrl).map(( zone ) => {
      let noteZone = 0;
      let maxZone = 0;

      if( zone.id === z ) {
        elements = e.map(( element ) => {
          let maxElement = 0;
          let noteElement = 0;
    
          const criters: CriterGrillePropType[] = element.criters.map(( criter ) => {
            let val = ( criter.val !== undefined ) ? criter.val : null;
            let coef = ( criter.coef && element.coef && zone.coef ) ? ( criter.coef * element.coef * zone.coef ) : 0;

            if( val !== null)  {
              maxElement += coef;
              noteElement += ( val * coef * meteo );
            }

            return {
              ...criter,
              val: val,
            };
          })
      
          if (maxElement > 0) {
            maxZone += maxElement;
            noteZone += noteElement;
          }

          return {
            ...element,
            criters: criters,
            note: maxElement > 0 ? ( (noteElement / maxElement) * 100 ) : null,
          };
        });
      } else {
        elements = zone.elements.map(( element ) => {
          let maxElement = 0;
          let noteElement = 0;
    
          const criters: CriterGrillePropType[] = element.criters.map(( criter ) => {
            let val = ( criter.val !== undefined ) ? criter.val : null;
            let coef = ( criter.coef && element.coef && zone.coef ) ? ( criter.coef * element.coef * zone.coef ) : 0;

            if( val !== null)  {
              maxElement += coef;
              noteElement += ( val * coef * meteo );
            }
    
            return {
              ...criter,
              val: val,
            };
          });

          if (maxElement > 0) {
            maxZone += maxElement;
            noteZone += noteElement;
          }
    
          return {
            ...element,
            criters: criters,
            note: maxElement > 0 ? ( (noteElement / maxElement) * 100 ) : null,
          };
        });
      }
  
      if (maxZone > 0) {
        maxCtrl += maxZone;
        noteCtrl += noteZone;
      }
  
      return {
        ...zone,
        elements: elements,
        note: maxZone > 0 ? ( (noteZone / maxZone) * 100 ) : null,
      };
    });

    let val = ( ( maxCtrl > 0 ) ) ? ( noteCtrl * 100 / maxCtrl ) : null;

    setNoteCtrl(val);
    setGrilleCtrl(newGrille);
  }

  return (
    <div className="localisation-ctrl-prop-component">
      <div className="localisation-ctrl-prop-content">
        <div className="button-container">
          <button
            className="btn btn-std"
            style={ { opacity: ( showGrilleProxi && !showGrilleContrat ) ? "1" : "0.5" } }
            key="proxi"
            onClick={ handelShowProxi }
          >Zones Proxi</button>

          <button
            className="btn btn-std"
            style={ { opacity: ( !showGrilleProxi && showGrilleContrat ) ? "1" : "0.5" } }
            key="contrat"
            onClick={ handelShowContrat }
          >Zones Contrat</button>

          <button
            className="btn btn-std"
            style={ { opacity: ( showGrilleProxi && showGrilleContrat ) ? "1" : "0.5" } }
            key="all"
            onClick={ handelShowAll }
          >Toutes les zones</button>
        </div>

        <div className="grille-container">
          { ( hasStructure && hasGrille && isMonted && ( Object.entries(grilleCtrl).length > 0 ) ) ? (
            <div className="cont-localisations-ctrl-prop">
              { Object.values(grilleCtrl).map(( zone ) => {
                return (
                  <ZonePropViewComponent
                    mbr={ mbr }
                    key={ zone.id }
                    zone={ zone }
                    conf={ conf }
                    isModifiable={ isModifiable }
                    zonesToShow={ 
                      ( showGrilleContrat && showGrilleProxi ) 
                        ? null 
                        : ( ( showGrilleProxi ) 
                          ? ZONE_TYPE_PROXI 
                          : ZONE_TYPE_CONTRAT ) 
                    }
                    zoneSelected={ zoneSelected }
                    onZoneChanged={ setGrilleChanged }
                    onZoneClicked={ onGrilleClicked }
                    onZoneSelected={ selectZone }
                  />
                )
              }) }
            </div>
          ) : (
            <div>Aucune localisation à afficher</div>
          ) }
        </div>
      </div>
    </div>
  )
};

export default LocalisationsCtrlProp;
