
import React, { FunctionComponent, useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
import { useHistory } from "react-router-dom";

import "./styles/controlProp.css";
import logo from '../assets/controlprop.png';

import { LIST_SELECT_TYPE_VIEW_AGENCE, LIST_SELECT_TYPE_VIEW_ENTRY, LIST_SELECT_TYPE_VIEW_GROUPEMENT, LIST_SELECT_TYPE_VIEW_RESIDENCE, SECRET_KEY } from "../models/consts";
import { CONTROL_PROP_ALLOWED_PATHS, LOGIN_URL } from "../models/consts/aplliUrls";
import { STORE_PARAM_CIBLE } from "../models/consts/store";
import { MbrType, initialMbr } from "../models/mbr";

import makeMbr from "../helpers/make-mbr";
import useConditionalRedirect from "../helpers/useConditionalRedirect";
import TestConect from "../helpers/test-conect";

import { useNavigation } from "../components/navigationContext";
import ListSelect from "../components/listSelect/listSelect";
import StatComponent from "../components/statistiques/statistiques";
import GrilleControlComponent from "../components/grilleControlProp/grilleControl";
import { FetchSetParamCible } from "../services/setParamCible";
import SearchEntryComponent from "../components/searchEntry";

const ControlProp: FunctionComponent = () => {
  useConditionalRedirect(CONTROL_PROP_ALLOWED_PATHS);

  const [mbr, setMbr] = useState<MbrType>(initialMbr);
  const [agc, setAgc] = useState(0);
  const [grp, setGrp] = useState(0);
  const [rsd, setRsd] = useState(0);
  const [entry, setEntry] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showStat, setShowStat] = useState(true);
  const [showGrille, setShowGrille] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const { setPreviousPath } = useNavigation();
  const history = useHistory();

  useEffect(() => {
    setPreviousPath(history.location.pathname);
  }, [history.location.pathname, setPreviousPath]);
  useEffect(() => {
    if( isMounted ) {
      if( entry > 0 ) {
        setShowStat(false);
        setShowGrille(true);
      } else {
        setShowGrille(false);
        setShowStat(true);
      }
    }
  }, [entry]);
  useEffect(() => {
    try {
      let isMounted = true;
            
      const testMbr = async () => {
        const user = await makeMbr();

        const response = await TestConect(user.pseudo, user.mdp, user.mac);

        if( isMounted ) {
          if( response === false ) {
            history.push(LOGIN_URL);
          } else {
            setMbr(prevMbr => ({
              ...prevMbr,
              ...user
            }));

            fetchData();
          }
        }
      };
            
      testMbr();

      return () => {
        isMounted = false;
      }
    } catch (error) {
      console.error('Error', error);
    }
  }, []);
  const fetchData = async () => {
    const encryptedDataCible = localStorage.getItem(STORE_PARAM_CIBLE);

    if( encryptedDataCible !== null ) {
      const decryptedDataCible = JSON.parse(CryptoJS.AES.decrypt(encryptedDataCible, SECRET_KEY).toString(CryptoJS.enc.Utf8));

      setAgc(( decryptedDataCible.agc === null ) ? 0 : decryptedDataCible.agc);
      setGrp(( decryptedDataCible.grp === null ) ? 0 : decryptedDataCible.grp);
      setRsd(( decryptedDataCible.rsd === null ) ? 0 : decryptedDataCible.rsd);
      setEntry(( decryptedDataCible.entry === null ) ? 0 : decryptedDataCible.entry);

      if( ( decryptedDataCible.entry !== null ) && ( decryptedDataCible.entry > 0 ) ) {
        setShowStat(false);
        setShowGrille(true);
      }

      setIsMounted(true);
    }
  };
  const handelListeTouch = () => {
    setMenuOpen(true);

    setTimeout(() => {
      setMenuOpen(false);
    }, 1000);
  };
  const setSelectedAgc = ( id: number ) => {
    setEntry(0);
    setRsd(0);
    setGrp(0);
    setAgc(id);

    FetchSetParamCible(id, 0, 0, 0);
  };
  const setSelectedGrp = ( id: number ) => {
    setEntry(0);
    setRsd(0);
    setGrp(id);
  
    FetchSetParamCible(agc, id, 0, 0);
  };
  const setSelectedRsd = ( id: number ) => {
    setEntry(0);
    setRsd(id);
  
    FetchSetParamCible(agc, grp, id, 0);
  };
  const setSelectedEntry = ( id: number ) => {
    setEntry(id);

    FetchSetParamCible(agc, grp, rsd, id);
  };
  const setResultSearch = ( a: number, g: number, r: number, e: number) => {
    setAgc(a);
    setGrp(g);
    setRsd(r);
    setEntry(e);

    FetchSetParamCible(a, g, r, e);
  };

  return (
    <div className="grille-control-screen">
      <div className="app-content">
        <div className="valign-wrapper">
          <div className="row">
            <div className="left-container col s12 m1 l3" >
              <img src={logo} alt="logo" />

              <SearchEntryComponent
                mbr={ mbr }
                onEntrySelected={ setResultSearch }
              />

              <div className="cont-lists">
                <ListSelect 
                  mbr={ mbr } 
                  type={ LIST_SELECT_TYPE_VIEW_AGENCE } 
                  parent={ agc } 
                  val={ agc } 
                  onTouchMenu={ handelListeTouch }
                  onSetSelect={ setSelectedAgc }
                />
                <ListSelect 
                  mbr={ mbr } 
                  type={ LIST_SELECT_TYPE_VIEW_GROUPEMENT } 
                  parent={ agc } 
                  val={ grp } 
                  onTouchMenu={ handelListeTouch }
                  onSetSelect={ setSelectedGrp }
                />
                <ListSelect 
                  mbr={ mbr } 
                  type={ LIST_SELECT_TYPE_VIEW_RESIDENCE } 
                  parent={ grp } 
                  val={ rsd } 
                  onTouchMenu={ handelListeTouch }
                  onSetSelect={ setSelectedRsd }
                />
                <ListSelect 
                  mbr={ mbr } 
                  type={ LIST_SELECT_TYPE_VIEW_ENTRY } 
                  parent={ rsd } 
                  val={ entry } 
                  onTouchMenu={ handelListeTouch }
                  onSetSelect={ setSelectedEntry }
                />
              </div>
            </div>

            <div className="col s12 m11 l9" >
              <div className="right-container">
                <StatComponent 
                  mbr={ mbr } 
                  agc={ agc } 
                  grp={ grp } 
                  rsd={ rsd } 
                  showFull={ showStat } 
                  onMenuOpen={ handelListeTouch }
                />

                { ( showGrille ) &&
                  <div className="cont-grill-ctrl" >
                    <GrilleControlComponent 
                      mbr={ mbr } 
                      id={ entry } 
                      menuOpen={ menuOpen }
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlProp;
